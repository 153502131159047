import { ReleaseType } from '../types/Products';

export const storyblokProductReleaseType = (blok: {
  releaseType: string | StoryblokReleaseType;
}): ReleaseType => {
  if (blok.releaseType === 'public-release') return 'public';
  if (blok.releaseType === 'draw-release') return 'draw';
  if (blok.releaseType === 'private-release') return 'private';
  if (blok.releaseType === 'timed-release') return 'timed';
  if (blok.releaseType === 'nft_ranked_auction') return 'nft_ranked_auction';
  if (blok.releaseType === 'auction') return 'auction';

  return 'public';
};
