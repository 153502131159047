import { gql } from '@apollo/client';

export const ALL_ARTISTS_QUERY = gql`
  query AllArtists($page: Int!, $per_page: Int!) {
    ArtistItems(page: $page, per_page: $per_page) {
      items {
        uuid
        created_at
        first_published_at
        content {
          name
          hideCollaborations
          excludeFromIndex
          studioGallery
          thumbnail {
            filename
            alt
            focus
          }
          plannedReleases
        }
        full_slug
        slug
      }
      total
    }
  }
`;

export const ALL_PRODUCTS_ARTIST_QUERY = gql`
  query AllProducts($page: Int!, $per_page: Int!) {
    ProductItems(page: $page, per_page: $per_page) {
      items {
        content {
          productArtist
        }
      }
      total
    }
  }
`;

export const PRODUCTS_BY_PATH_QUERY = gql`
  query ProductsByPath($path: String!) {
    ProductItems(starts_with: $path) {
      items {
        content {
          id
          thumbnail {
            filename
            alt
            focus
          }
        }
        full_slug
      }
      total
    }
  }
`;

export const ALL_SLUGS_PER_TOPIC = gql`
  query AllSlugs($startsWith: String!) {
    Links(starts_with: $startsWith) {
      items {
        slug
        isFolder
        isStartpage
        published
      }
    }
  }
`;

export const GET_STORYBLOK_PRODUCTS_BY_SLUGS = gql`
  query GetStoryblokProductsBySlugs($slugs: String!) {
    products: ProductItems(page: 1, per_page: 100, by_slugs: $slugs) {
      items {
        full_slug
        content {
          images
          highlightImage {
            filename
          }
        }
      }
    }
  }
`;

export const GET_STORYBLOK_PRODUCTS_FOR_SITEMAP = gql`
  query GetStoryblokProductsForSitemap($page: Int!) {
    products: ProductItems(per_page: 100, page: $page) {
      items {
        full_slug
        content {
          excludeFromIndex
          isPrivateOffer
        }
      }
    }
  }
`;
