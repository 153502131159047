import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { from } from '@apollo/client/link/core';
import { HttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'cross-fetch';

import { isStoryblokDraftMode } from '../../utils/storyblok';

const headersLink = setContext((_, ctx) => ({
  headers: {
    token: process.env.STORYBLOK_TOKEN,
    version: isStoryblokDraftMode() ? 'draft' : 'published',

    // Support overriding headers per request
    ...(ctx.headers as Record<string, string>),
  },
}));

const httpLink = new HttpLink({
  fetch,
  uri: 'https://gapi.storyblok.com/v1/api',
});

const retryLink = new RetryLink();

export const storyblokApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([headersLink, retryLink, httpLink]),
});
