import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { from } from '@apollo/client/link/core';
import { HttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'cross-fetch';

const authorisationLink = setContext(() => ({
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_TOKEN,
  },
}));

const httpLink = new HttpLink({
  fetch,
  uri:
    process.env.NEXT_PUBLIC_SHOPIFY_GQL_CLIENT_URI ||
    'https://store.avantarte.com/api/2023-04/graphql.json',
});

const retryLink = new RetryLink();

/*
  The Shopify Storefront client is used for client-side requests to Shopify GraphQL API.
*/
export const storefrontClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authorisationLink, retryLink, httpLink]),
});
