import type { FrameStyle } from '../types/Products';

export const PRODUCT_TAG_DISABLE_EXPRESS = 'Disable Express Checkout';
export const PRODUCT_TAG_DISABLE_PAYPAL = 'Disable Paypal';
export const PRODUCT_TAG_DISABLE_CRYPTO = 'Disable crypto';
export const PRODUCT_TAG_ALLOW_MULTIPLE_ITEMS = 'Allow multiple items';
export const PRODUCT_TAG_AVAILABLE = 'Available';
export const PRODUCT_TAG_COA_2_YEAR_DISPATCH = 'COA 2 year dispatch';
export const PRODUCT_TAG_COA_3_YEAR_DISPATCH = 'COA 3 year dispatch';
export const PRODUCT_TAG_COA_4_YEAR_DISPATCH = 'COA 4 year dispatch';
export const PRODUCT_TAG_COA_5_YEAR_DISPATCH = 'COA 5 year dispatch';
export const PRODUCT_TAG_COMING_SOON = 'Coming soon';
export const PRODUCT_TAG_FREE_SHIPPING = 'Free shipping';
export const PRODUCT_TAG_HANDFINISHED = 'Handfinished';
export const PRODUCT_TAG_MEDIUM_COLLECTIBLE = 'Collectible';
export const PRODUCT_TAG_MEDIUM_NFT = 'NFTs';
export const PRODUCT_TAG_MEDIUM_PRINT = 'Print edition';
export const PRODUCT_TAG_MEDIUM_SCULPTURE = 'Sculpture edition';
export const PRODUCT_TAG_MEDIUM_STUDIO_WORKS = 'Studio works';
export const PRODUCT_TAG_NO_ORDER_CANCELLATION = 'No order cancellation';
export const PRODUCT_TAG_NO_RESALE_AGREEMENT = 'No resale agreement';
export const PRODUCT_TAG_3_YEAR_RESALE_AGREEMENT = '3 year resale agreement';
export const PRODUCT_TAG_4_YEAR_RESALE_AGREEMENT = '4 year resale agreement';
export const PRODUCT_TAG_5_YEAR_RESALE_AGREEMENT = '5 year resale agreement';
export const PRODUCT_TAG_NO_TAX_CHARGED = 'No tax charged';
export const PRODUCT_TAG_PINNED_ON_HOMEPAGE = 'Pinned on homepage';
export const PRODUCT_TAG_SAME_PRICE = 'Same price';
export const PRODUCT_TAG_SOLD_OUT = 'Sold out';
export const PRODUCT_TAG_OPEN_EDITION = 'Open edition';
export const PRODUCT_TAG_ONE_TAP_CHECKOUT = 'One tap purchase';
export const PRODUCT_TAG_LIMIT_ONE_PER_COSTUMER = 'Limit one per costumer';
export const PRODUCT_TAG_ENQUIRE_ONLY_NO_PRICE = 'Enquire only no price';
export const PRODUCT_TAG_FEW_LEFT = 'Few left';
export const PRODUCT_TAG_SOCIAL_SHARE = 'Social share';
export const PRODUCT_TAG_NO_COA = 'No COA';

export const PRODUCT_FRAME_DEFAULT_VARIANT_TITLE_NORMALISED =
  'uv-protective-acrylic';
export const PRODUCT_FRAME_OPTIMUM_GLASS_VARIANT_TITLE_NORMALISED =
  'optium-Museum-acrylic';

// TODO: Remove after Christmas Delivery Period
export const PRODUCT_TAG_CHRISTMAS_DELIVERY_2022 = 'Christmas delivery 2022';

export const PRODUCT_DISPATCH_DEFAULT = 2;
export const PRODUCT_DISPATCH_DEFAULT_FRAMED = 4;

export const PRODUCT_TIMED_RELEASE_DEFAULT_HOURS = 24;

export const AML_LIMIT = 10000;

export const FRAME_TYPE_COLOUR_MAP: Record<StoryblokFrameType, FrameStyle> = {
  'White frame': { textColour: 'dark', frameColour: '#FFFFFF' },
  'Light frame': { textColour: 'dark', frameColour: '#D9D1C7' },
  'Dark frame': { textColour: 'light', frameColour: '#533E21' },
  'Black frame': { textColour: 'light', frameColour: '#000000' },
  'Custom frame': { textColour: 'dark', frameColour: '#FFFFFF' },
};

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const FIRST_SUPPORTED_DRAW_LAUNCH = new Date(2022, 10, 24, 0, 0, 0, 0);
