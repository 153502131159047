import {
  ProductPurchaseDetails,
  ShopifyFrameProduct,
  ShopifyFrameWithSelectedVariant,
} from '../types/Purchasing';

export const framedByDefault = (
  framingOffered?: ProductPurchaseDetails['framingOffered'],
) => {
  return framingOffered === 'Frame included';
};

export const normaliseVariantName = (name: string) =>
  name.toLowerCase().trim().replace(/\s+/g, '-');

export const getFrameBaseVariant = (
  frame: ShopifyFrameProduct | ShopifyFrameWithSelectedVariant,
) => {
  // We need to show the cheapest variant as the price as the other ones will be displayed as upgrades relative to this base prices
  const baseVariant = frame.variants.reduce((prev, current) =>
    prev.price < current.price ? prev : current,
  );

  return baseVariant;
};

export const hasFrameUpgradeOptions = (frames: ShopifyFrameProduct[] = []) =>
  frames.filter((frame) => frame.variants.length > 1).length > 0;
