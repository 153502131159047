export const shopifyAdminIdToGraphqlId = (adminId: string): string => {
  return `gid://shopify/Product/${adminId}`;
};

export const shopifyGraphqlIdToAdminId = (graphqlId: string): string => {
  const parts = graphqlId.split('/');
  return parts[parts.length - 1];
};

export const listGraphqlIds = (productId?: ShopifyProductIdentifier[]) => {
  return (productId || []).map(({ id }) => shopifyAdminIdToGraphqlId(id));
};
