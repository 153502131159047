import { getCountryByCurrency } from '@utils/locations';

import {
  ALL_PRODUCTS_COUNT_QUERY,
  ALL_PRODUCTS_QUERY,
  PRODUCT_PRICE_AND_TAGS_BY_ID_QUERY,
} from '../../apollo/shopify/queries';
import { storefrontClient } from '../../apollo/shopify/storefrontClient';
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_CURRENCY_CODE,
} from '../../constants/locations';
import {
  ShopifyGenAllProductsQuery,
  ShopifyGenProductPriceTagsQuery,
} from '../../types/shopifygen';

const PRODUCTS_PER_PAGE = 100;

export const fetchProductPriceAndTagsOnly = async (id: string) => {
  const {
    data: { node },
  } = await storefrontClient.query<ShopifyGenProductPriceTagsQuery>({
    query: PRODUCT_PRICE_AND_TAGS_BY_ID_QUERY,
    variables: {
      id,
      country: DEFAULT_COUNTRY_CODE,
    },
    fetchPolicy: 'no-cache',
  });

  if (node?.__typename === 'Product') {
    return node;
  }

  return null;
};

export const fetchAllProducts = async (queryFilters: string) => {
  let cursor = '';
  let reachedLastPage = false;

  let allWorks: ShopifyGenAllProductsQuery['products']['edges'] = [];
  while (!reachedLastPage) {
    const { data } = await storefrontClient.query<ShopifyGenAllProductsQuery>({
      query: ALL_PRODUCTS_QUERY,
      variables: {
        after: cursor || null,
        country: DEFAULT_COUNTRY_CODE,
        filters: queryFilters,
        limit: PRODUCTS_PER_PAGE,
      },
      fetchPolicy: 'no-cache',
    });

    allWorks = [...allWorks, ...data.products.edges];
    cursor = getCursor(data.products.edges);
    reachedLastPage = !data.products.pageInfo.hasNextPage;
  }

  return allWorks;
};

export const fetchAllProductsCount = async (queryFilters: string) => {
  let cursor = '';
  let reachedLastPage = false;
  let count = 0;

  while (!reachedLastPage) {
    const {
      data: {
        products: {
          edges: works,
          pageInfo: { hasNextPage },
        },
      },
    } = await storefrontClient.query({
      query: ALL_PRODUCTS_COUNT_QUERY,
      variables: {
        after: cursor || null,
        country: getCountryByCurrency(DEFAULT_CURRENCY_CODE),
        filters: queryFilters,
        limit: PRODUCTS_PER_PAGE,
      },
    });

    count += works.reduce((total: number, work: any) => {
      const workCount =
        work.node.productType === 'Collection'
          ? Number(work.node.editionSize.value)
          : 1;

      return total + workCount;
    }, 0);

    cursor = getCursor(works);
    reachedLastPage = !hasNextPage;
  }

  return count;
};

const getCursor = (items?: { cursor: string }[]): string => {
  if (items === undefined) return '';
  if (items.length === 0) return '';

  return items.slice(-1)[0].cursor;
};
